@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fortawesome/fontawesome-free/css/all.min.css";

/* Custom styles */
body {
  font-family: 'Inter', sans-serif;
}

@keyframes backgroundColorChange {
  0% {
    background-color: #1a202c;
  }

  25% {
    background-color: #2d3748;
  }

  50% {
    background-color: #4a5568;
  }

  75% {
    background-color: #2d3748;
  }

  100% {
    background-color: #1a202c;
  }
}

@keyframes backgroundColorChangeWhite {
  0% {
      background-color: #e7e9ee;
    }
  
    25% {
      background-color: #858fa1;
    }
  
    50% {
      background-color: #a2aab9;
    }
  
    75% {
      background-color: #ced3dd;
    }
  
    100% {
      background-color: #e7e9ee;
    }
}

.animated-background {
  animation: backgroundColorChange 20s infinite;
}

.animated-background-white {
  animation: backgroundColorChangeWhite 20s infinite;
}

@keyframes move {
  0% {
    transform: translateY(0) translateX(0);
  }

  50% {
    transform: translateY(-20px) translateX(20px);
  }

  100% {
    transform: translateY(0) translateX(0);
  }
}

/* Existing styles */
.circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.6;
  animation: move 10s infinite alternate;
}

/* Default circle sizes and positions */
.circle-1 {
  width: 150px;
  height: 150px;
  background: rgba(255, 255, 255, 0.2);
  top: 20%;
  left: 15%;
  animation-delay: 0s;
}

.circle-2 {
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 255, 0.2);
  top: 50%;
  left: 70%;
  animation-delay: 2s;
}

.circle-3 {
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.2);
  top: 80%;
  left: 30%;
  animation-delay: 4s;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .circle-1 {
    width: 100px;
    height: 100px;
    top: 10%;
    left: 10%;
  }

  .circle-2 {
    width: 150px;
    height: 150px;
    top: 40%;
    left: 60%;
  }

  .circle-3 {
    width: 75px;
    height: 75px;
    top: 70%;
    left: 20%;
  }
}

@media (max-width: 480px) {
  .circle-1 {
    width: 80px;
    height: 80px;
    top: 5%;
    left: 5%;
  }

  .circle-2 {
    width: 120px;
    height: 120px;
    top: 30%;
    left: 50%;
  }

  .circle-3 {
    width: 60px;
    height: 60px;
    top: 60%;
    left: 10%;
  }
}